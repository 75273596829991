// 获取当前时间
export const getTime = ()=>{
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const time = {
        year:year,
        month:month,
        day:day,
        hours:hours,
        minutes:minutes
    }
    return time
}