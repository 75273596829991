import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";  // 轨道控制器
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";      // 引入 gltf 模型加载器
import { GUI } from "three/examples/jsm/libs/lil-gui.module.min.js";
import { CSS3DRenderer, CSS3DObject, } from "three/examples/jsm/renderers/CSS3DRenderer.js";
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js';
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
// 定义所有数据
const scaling = 1000                   // 整个场景缩放比例
// 三维坐标系 辅助
export const coordinates = (sceneGL) => {
    const axesHelper = new THREE.AxesHelper(20000);
    sceneGL.add(axesHelper); // 坐标轴对象添加到三维场景中
}
// 创建光源
export const establishLight = (sceneGL, rendererGL) => {

    // 可视化点光源 辅助
    // const pointLightHelper1 = new THREE.PointLightHelper(pointLight5, 20, 0xff0000)
    // sceneGL.add(pointLightHelper1)
    const gui = new GUI()							// 引入
    gui.domElement.style.right = '0px'				 // 定位右侧 0 px
    gui.domElement.style.width = '500px'			 // 宽度设置
    // 将参数添加到 GUI 控制面板中
    const lightFolder = gui.addFolder('平行光设置');
    // lightFolder.addColor(planeLight2, 'color').onChange(function (value) {
    //     planeLight2.color.set(value);
    // });
    // lightFolder.add(lightParams, 'positionX', -100, 100).onChange(function (value) {
    //     console.log("planeLight1.position", planeLight1.position, value)
    //     planeLight1.position.x = value;
    // });
    // lightFolder.add(lightParams, 'positionY', -100, 100).onChange(function (value) {
    //     planeLight1.position.y = value;
    // });
    // lightFolder.add(lightParams, 'positionZ', -100, 100).onChange(function (value) {
    //     planeLight1.position.z = value;
    // });
    lightFolder.open(); // 展开控制面板
}

// 创建相机
export const establishCamera = (mountDom) => {
    let camera = new THREE.PerspectiveCamera(20, mountDom.value.clientWidth / mountDom.value.clientHeight, 1, 50000); //视锥体垂直视野角度  宽高比 近端面距离 远端面距离
    camera.position.set(-5530, 6067, 22000); // 设置相机位置
    return camera
}
// 相机控件
export const cameraChange = (controls,camera, sceneGL, rendererGL, sceneDOM, rendererCSS) => {
     controls = new OrbitControls(camera, rendererGL.domElement);       // 9.添加拖拽事件
    controls.rotateSpeed = 0.4;     // 旋转速度
    controls.panSpeed = 0.7;     // 右击位移速度
    controls.zoomSpeed = 0.7;     // 相机缩放速度
    controls.maxPolarAngle = Math.PI / 180 * 90;     // 设置垂直角度限制
    // controls.minPolarAngle = Math.PI / 180 * 50;     // 设置垂直角度限制
    // controls.maxDistance = 2000;     // 设置缩放最大距离
    // controls.minDistance = 500;     // 设置缩放最小距离
    controls.target.set(1000, 2000, 0); // 设置 目标位置
    controls.update();
    controls.addEventListener("change", () => {
        rendererGL.render(sceneGL, camera);
        rendererCSS.render(sceneDOM, camera);
        console.log("相机控件", camera.position)
    });
    return controls
    // 打开控制台手机上
    // let consoleScript = document.createElement("script");
    // consoleScript.src = "https://cdn.bootcss.com/vConsole/3.3.4/vconsole.min.js";
    // consoleScript.onload = function () {
    //   new VConsole()
    // }
    // document.head.appendChild(consoleScript);
}
// 创建 gl 渲染器
export const creaRendererGl = (mountDom) => {
    const rendererGL = new THREE.WebGLRenderer({
        antialias: true, // 抗锯齿
        logarithmicDepthBuffer: true			// 解决闪烁
    });
    rendererGL.setClearAlpha(0)
    rendererGL.domElement.style.position = "absolute";
    rendererGL.domElement.style.zIndex = 0;
    rendererGL.domElement.style.top = 0;
    rendererGL.setPixelRatio(window.devicePixelRatio); // 设置 当前屏幕的像素比例
    rendererGL.setClearColor(0x000000); // 设置渲染器背景颜色
    rendererGL.setSize(mountDom.value.clientWidth, mountDom.value.clientHeight);    // 设置尺寸 配置画布的宽度和高度
    rendererGL.toneMapping = THREE.ReinhardToneMapping;                             // 设置滤镜
    // rendererGL.toneMappingExposure = 1.0
    return rendererGL
}
// 创建 css 渲染器
export const creaRendererCSS = (rendererGL, mountDom) => {
    const rendererCSS = new CSS3DRenderer();
    rendererCSS.setSize(mountDom.value.clientWidth, mountDom.value.clientHeight); // 设置尺寸 配置画布的宽度和高度
    rendererCSS.domElement.style.position = "absolute";
    rendererCSS.domElement.style.zIndex = 2;
    rendererCSS.domElement.style.top = 0;
    // 渲染的dom
    rendererCSS.domElement.appendChild(rendererGL.domElement);
    mountDom.value.appendChild(rendererCSS.domElement);          // 将渲染器渲染出的内容插入dom
    return rendererCSS
}

// 加载模型
export const toLoadModel = async (sceneGL) => {
    return new Promise((resolve, reject) => {
        const loader = new GLTFLoader();
        loader.load('./mainModel.glb', (gltf) => {
            gltf.scene.scale.setScalar(scaling);                    // 设置场景缩放
            sceneGL.add(gltf.scene);
            resolve(); // 表示加载完成
        });
    });
}
// 场景 插入 dom
export const insertDOM = (display, sceneDOM, displayDOM) => {
    const penetrateDomMesh = new THREE.MeshPhysicalMaterial({            // 创建穿透 dom 材质
        color: '#000000', 		// 必添
        opacity: 0,
        blending: THREE.NoBlending,
    });
    display.material = penetrateDomMesh
    console.log("display", display.geometry)
    console.log("获取角度", display.rotation)
    // 获取模型的位置
    let position = display.position;
    console.log("获取模型位置:", position);
    // 获取平面的几何体
    let geometry = display.geometry;
    // 计算平面在 x 轴上的尺寸
    geometry.computeBoundingBox();
    let boundingBox = geometry.boundingBox;
    console.log("获取平面尺寸", boundingBox)
    let width = (boundingBox.max.x - boundingBox.min.x) * scaling;
    let height = Math.max((boundingBox.max.y - boundingBox.min.y) * scaling, (boundingBox.max.z - boundingBox.min.z) * scaling);
    console.log("width", height)
    displayDOM.value.style.width = Math.round(width) + 'px';
    displayDOM.value.style.height = Math.round(height) + 'px';
    let sceneDomTs = new CSS3DObject(displayDOM.value)
    // console.log(" position.y * scaling",THREE.MathUtils.degToRad(10))
    sceneDomTs.rotation.x = display.rotation.x*10
    sceneDomTs.position.x = position.x * scaling
    sceneDomTs.position.y = position.y * scaling
    sceneDomTs.position.z = position.z * scaling
    console.log("display.rotation 获取旋转角度",display.rotation.x,THREE.MathUtils.degToRad(180))
    // 
    sceneDOM.add(sceneDomTs)
}

