<!-- echarts 代码生成器组件 -->
<template>
  <div class="echartsOutCont">
    默认输出玻璃组件
  </div>
</template>
<script setup>

</script>
<style lang="scss" scoped>
.echartsOutCont {
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: rgb(255, 255, 255)
}
</style>