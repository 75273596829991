<template>
    <div class="loadPage red">
        <!-- 顶部介绍 -->
        <div class="describeCont" ref="Ref_describeCont">
            <span v-html="pageData.textArr"></span>
            <span class="typingCursor">&nbsp;|</span>
        </div>
        <div v-if="pageData.loadingShow && props.loadState" class="nextCont red">
            <div class="container">
                <div class="btn"><a href="#" @click="emits('close')">立即进入</a></div>
            </div>
        </div>
        <div v-else-if="pageData.loadingShow" class="nextCont red">
            <div class="loadCont red">
                <span style="--i:1;">l</span>
                <span style="--i:2;">o</span>
                <span style="--i:3;">a</span>
                <span style="--i:4;">d</span>
                <span style="--i:5;">i</span>
                <span style="--i:6;">n</span>
                <span style="--i:7;">g</span>
                <span style="--i:8;">.</span>
                <span style="--i:9;">.</span>
                <span style="--i:10;">.</span>
            </div>
        </div>
        <div class="icpNumberCont">
            <a href="https://beian.miit.gov.cn/" target="_blank">冀ICP备2024065258号-1</a>
        </div>
    </div>
</template>
<script setup>
import { ref, reactive, onMounted, nextTick } from 'vue'
const emits = defineEmits(['close'])
const props = defineProps({
    loadState: {
        type: Boolean,
        default: false
    },
})
let Ref_describeCont = ref(null)
let pageData = reactive({
    textArr: "",
    loadingShow: false,
})
onMounted(() => {
    nextTick(() => {
        let textArr = `项目功能：
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            1、win10桌面模拟,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            模拟 win10 电脑桌面 , 支持基本操作 , 如： 拖拽文件夹 、 右击 - 重命名 \ 新建 、 双击文件夹等
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            dom元素与 gl 元素融合后在 canvas 画布上显示时 , three场景缩放仍保留拖拽精准度
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            2、echarts 可视化开发工具
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            可视化配置 echarts 的配置数据,无需安装依赖,自动生成 ecahrts 语法代码
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            3、AI 智能寻路贪吃蛇
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            JavaScript 手写 A* 算法,及自设计算法逻辑,实现自动寻路优先最短路径寻找食物且避开自身障碍,实现贪吃蛇最优解
            <br />
            <br />
            操作介绍：
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            鼠标滚轮滚动,实现缩放
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            鼠标左键长按拖拽,实现旋转切换角度
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            鼠标右键长按拖拽,实现位移相机
            <br />
            <br />
            此项目采用 vue3 进行开发
            <br />
            此项目 无任何后端接口请求 , network 均为前端代码程序及相关静态资源
            <br />
            此项目 基于 element plus 、three.js 、echarts 、Prism.js 等依赖进行开发 , blender 建模
            <br />
            <br />
            警告！！！个人研究项目,非开源项目,仅用于个人技术展示,3D案例分享,严禁用于商用及其他用途
            <br />
            <br />
            <br />
            开发者：刘**
            <br />
            联系方式：132319099**
            <br />
            <br />
            icp备案号：冀ICP备2024065258号-1
            <br />
            公安备案号：审核中
            <br />
            <br />
            正在准备模型,因测试服务器带宽较小,请稍等...
            `
        let first = false;
        let timer = setInterval(() => {
            if (textArr.indexOf("<br />") == 0) {
                textArr = textArr.replace("<br />", "");
                pageData.textArr += "<br />"
                if (!first) {
                    first = true
                    pageData.textArr += "<span>"
                } else {
                    pageData.textArr += "</span><span>"
                }
            } else if (textArr.indexOf("&nbsp;") == 0) {
                textArr = textArr.replace("&nbsp;", "");
                pageData.textArr += "&nbsp;"
            } else {
                pageData.textArr += textArr[0]
                textArr = textArr.substring(1);
            }
            if (textArr.length == 0) {
                pageData.textArr += "</span>"
                pageData.loadingShow = true
                clearInterval(timer)
            }
        }, 10);

    })
})
</script>
<style lang="scss" scoped>
.loadPage {
    font-size: 15px;
    color: #b3fff6;
    line-height: 30px;
    position: relative;
    height: 100%;

    .icpNumberCont {
        position: absolute;
        z-index: 50;
        bottom: 10px;
        text-align: center;
        width: 100%;

        a {
            color: rgb(186 247 255) !important;
        }
    }

    .describeCont {

        .typingCursor {
            font-size: 16px;
            font-weight: bolder;
            animation: flicker 0.2s linear infinite;
        }

        @keyframes flicker {
            0% {
                color: #000
            }

            25% {
                color: #b3fff6
            }

            75% {
                color: #b3fff6
            }

            100% {
                color: #000
            }
        }
    }



    .nextCont {
        position: absolute;
        right: 100px;
        bottom: 50px;
        animation: slowShow 2s linear;
        animation-fill-mode: forwards;

        @keyframes slowShow {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        .loadCont {
            /* 相对定位 */
            position: relative;
            /* 倒影效果 */
            -webkit-box-reflect: below -1px linear-gradient(transparent, rgba(0, 0, 0, 0.3));

            span {
                position: relative;
                display: inline-block;
                color: #75ffef;
                font-size: 40px;
                text-transform: uppercase;
                letter-spacing: 8px;
                /* 执行动画：动画名 时长 加速后减速 无限次播放 */
                animation: wavyAnimate 1s ease-in-out infinite;
                /* 通过var函数调用自定义属性--i,在计算出动画延迟时间 */
                animation-delay: calc(0.1s * var(--i));
            }

            /* 定义动画 */
            @keyframes wavyAnimate {
                0% {
                    transform: translateY(0);
                }

                20% {
                    transform: translateY(-20px);
                }

                40%,
                100% {
                    transform: translateY(0);
                }
            }
        }

        .container {
            /* 弹性布局 */
            display: flex;
            /* 允许换行 */
            flex-wrap: wrap;
            /* 将元素靠边对齐 */
            justify-content: space-around;


            .btn {
                position: relative;
                width: 200px;
                height: 60px;
                margin: 30px;
                animation: flickerBtn 2s linear infinite;
                border-radius: 30px;

                @keyframes flickerBtn {
                    0% {
                        border: 1px solid rgb(0, 0, 0);
                    }

                    15% {
                        border: 1px solid rgb(158, 240, 255);
                    }

                    30% {
                        border: 1px solid rgb(0, 0, 0);
                    }

                    45% {
                        border: 1px solid rgb(158, 240, 255);

                    }

                    100% {
                        border: 1px solid rgb(0, 0, 0);
                    }
                }

                a {

                    /* 绝对定位 */
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    /* 透明度为0.05的白色背景 */
                    background: rgba(255, 255, 255, 0.05);
                    /* 阴影 */
                    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
                    /* 上下边框 */
                    border-top: 1px solid rgba(255, 255, 255, 0.1);
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    /* 圆角 */
                    border-radius: 30px;
                    color: #98eaff !important;
                    font-size: 16px;
                    z-index: 1;
                    /* 字间距 */
                    letter-spacing: 1px;
                    /* 去下划线 */
                    text-decoration: none;
                    /* 动画过渡 */
                    transition: 0.5s;
                    /* 溢出隐藏 */
                    overflow: hidden;
                    /* 背景模糊 */
                    backdrop-filter: blur(15px);

                    &::before {
                        content: "";
                        /* 绝对定位 */
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 50%;
                        height: 100%;
                        /* 渐变背景 透明到白色 */
                        background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.15));
                        /* 沿X轴倾斜45度，向右平移0像素 */
                        transform: skewX(45deg) translateX(0);
                        transition: 0.5s;

                    }
                }

                &:hover a {
                    letter-spacing: 5px;
                }
            }

            .btn:hover a::before {
                /* 沿X轴倾斜45度，向右平移200% */
                transform: skewX(45deg) translateX(200%);
            }
        }




    }
}
</style>