<!-- echarts 应用打开后 -->
<template>
  <div class="max-u-cont red">
    <!-- 选择图标界面 -->
    <div class="echarts-select-chart-cont red" v-if="pageData.step == 1">
      <SelectEcharts :DataArr="pageData.data" @confirmEcharts="confirm"></SelectEcharts>
    </div>
    <!-- 开始配置界面 -->
    <div class="echarts-install-cont red" v-else-if="pageData.step == 2">
      <Customization :Data="pageData.targetEcharts"></Customization>
    </div>
    <!-- 退出按钮 -->
    <div class="exit-button red" v-html="svg_echarts_exit" @click="emits('close')">
    </div>
  </div>
</template>
<script setup>  

import { svg_echarts_exit } from "@/modules/winSystem/assets/js/iconArr";
import { useStore } from 'vuex';
import { reactive, onMounted } from "vue";
import { ecahrtsData } from "./data"
// 选择界面
import SelectEcharts from "./modules/selectEcharts";    
// 配置界面
import Customization from "./modules/customization";
const store = useStore();
let pageData = reactive({
  // echarts 循环渲染数据
  data: [],
  // 当前进度 1 表示选择图形界面 2 表示配置页
  step: 1,
  // 点击的 echarts 数据
  targetEcharts: {}

})
pageData.data = ecahrtsData



// 点击进入到配置界面
const confirm = (data) => {
  pageData.targetEcharts = data
  pageData.step = 2
}




const emits = defineEmits(['close'])
</script>
<style lang="scss" scoped>
.max-u-cont {
  width: 100%;
  height: 100%;
  position: relative;
  backdrop-filter: blur(45px);

  .red {
  }
  .echarts-select-chart-cont {
    height: 100%;
    width: 100%;
  }

  .echarts-install-cont {
    height: 100%;
  }

  .exit-button {
    position: absolute;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 3px rgb(255, 4, 4);
    right: 15px;
    top: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    animation: closeButton 2s linear infinite;

    ::v-deep svg {
      height: 30px;
      width: 30px;
    }

    &:hover {
      cursor: pointer;
      // box-shadow: 0px 0px 15px 6px rgb(255, 94, 0);

      ::v-deep svg {
        height: 30px;
        width: 30px;
        path {
          fill: rgb(231, 85, 0);
        }
      }

      animation: closeButtonHover 1s linear infinite;
    }

    @keyframes closeButton {
      0% {
        box-shadow: 0px 0px 10px 3px rgb(255, 4, 4);
      }

      10% {
        box-shadow: 0px 0px 15px 4px rgb(255, 128, 54);
      }

      60% {
        box-shadow: 0px 0px 10px 3px rgb(255, 4, 4);
      }
    }

    @keyframes closeButtonHover {
      0% {
        box-shadow: 0px 0px 6px 3px rgb(255, 4, 4);
      }

      50% {
        box-shadow: 0px 0px 6px 3px rgb(255, 128, 54);
      }

      100% {
        box-shadow: 0px 0px 6px 3px rgb(255, 4, 4);
      }
    }
  }
}
</style>