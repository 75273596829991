<!-- echarts 大屏显示代码 -->
<template>
  <div class="ecahrtsCont">
    echatrs 代码生成器001
  </div>
</template>

<style lang="scss" scoped>
.ecahrtsCont {
  width: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;
  background-color: rgb(113, 255, 255);
  color: red;
}
</style>