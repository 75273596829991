<!-- echats 选择界面 -->
<template>
  <div class="echarts-max-u-cont red">
    <!-- 标题 -->
    <div class="echarts-select-title red">
      <span>
        请选择 echarts 模型
      </span>
    </div>
    <!-- 图例容器 -->
    <div class="echarts-select-model-cont red">
      <!-- 饼状图 -->
      <div class="echarts-select-model-series-cont red" v-for="item in props.DataArr" :key="item.type">
        <!-- 系列标题 -->
        <div class="echarts-select-model-series-title-cont red">
          <div></div>
          <div>{{ item.name }}</div>
          <div></div>
        </div>
        <!-- 系列所有样式模板 -->
        <div class="echarts-select-model-item-cont red">
          <div class="echarts-select-model-item red" v-for="itm in item.data" :key="itm.name" @click=" emits('confirmEcharts', itm)">
            <div :id="itm.type" class="echarts-select-model">{{ itm.getOptions }}</div>
            <div class="echarts-select-model-mask"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import * as echarts from 'echarts';
import { onMounted } from 'vue'
const props = defineProps({
  DataArr: {
    type: Object,
    default: {}
  },
})
onMounted(() => {
  init()
})
// 初始化
const init = () => {
  props.DataArr.forEach(item => {
    item.data.forEach(itm => {
      renderEcharts(itm)
    })
  })
}
// 渲染 echarts 图形
const renderEcharts = (item) => {
  const chartDom = document.getElementById(item.type);
  const myChart = echarts.init(chartDom);
  myChart.setOption(item.getOptions());
}
// 自定义事件 选择 echarts 图
const emits = defineEmits(['confirmEcharts'])

</script>
<style lang="scss" scoped>
.echarts-max-u-cont {
  height: 100%;

  display: flex;
  flex-direction: column;

  .echarts-select-title {
    padding-top: 20px;
    text-align: center;
    -webkit-box-reflect: below 1px
      linear-gradient(transparent, rgba(0, 0, 0, 0.2));

    span {
      color: #fff;
      font-size: 36px;
      font-weight: bold;
      /* 字间距 */
      letter-spacing: 15px;
      /* 转大写 */
      text-align: center;
      outline: none;
      /* 自定义属性--c，可通过var函数对其调用 */
      --c: lightseagreen;
      /* 调用自定义属性--c，设置文字阴影（发光效果） */
      text-shadow: 0 0 10px var(--c), 0 0 20px var(--c), 0 0 40px var(--c),
        0 0 80px var(--c), 0 0 160px var(--c);
      /* 执行动画：动画名 时长 线性的 无限次播放 */
      animation: animate 3s linear infinite;
    }

    /* 定义动画 */
    @keyframes animate {
      to {
        /* 色相旋转滤镜（设置度数可改变颜色） */
        filter: hue-rotate(360deg);
      }
    }
  }

  .echarts-select-model-cont {
    flex: 1;
    margin: 0 100px;
    overflow: auto;

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 0px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 0px;
      scrollbar-arrow-color: red;
    }

    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      -webkit-box-shadow: inset 0 0 3px rgba(255, 2, 2, 0.2);
    }

    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 0px rgba(255, 156, 156, 0.2);
      border-radius: 0;
      background: rgba(255, 152, 152, 0.1);
    }

    .echarts-select-model-series-cont {
      .echarts-select-model-series-title-cont {
        display: flex;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 30px;

        & div:nth-child(1) {
          background-color: #ff3300;
          height: 3px;
          width: 100px;
        }

        & div:nth-child(2) {
          color: #ffffff;
          font-size: 28px;
          margin: 0px 20px;
        }

        & div:nth-child(3) {
          flex: 1;
          background-image: linear-gradient(45deg, #ff3300, #f0f6ff);
          height: 2px;
          border-radius: 0px 1px 1px 0px;
        }
      }

      .echarts-select-model-item-cont {
        margin-left: 225px;
        display: flex;
        flex-wrap: wrap;

        .echarts-select-model-item {
          width: 350px;
          height: 230px;
          margin: 0px 10px;
          margin-bottom: 20px;
          position: relative;
          box-shadow: 1px 1px 6px 2px #ececec;
          cursor: pointer;

          &:hover {
            box-shadow: 1px 1px 6px 2px #ff4747;
          }

          .echarts-select-model {
            width: 100%;
            height: 100%;
          }

          .echarts-select-model-mask {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>