<template>
    <div class="controlPage red">
        <div class="rigtControlCont">
            <!-- 主页 -->
            <div class="icon-box" @click="emits('cameraFlight',0)">
                <a href="#">
                    <div class="layer">
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i class="fa">
                            <svg t="1717822179482" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="9340" width="200" height="200">
                                <path
                                    d="M933.236364 460.8L563.2 90.763636c-27.927273-27.927273-74.472727-27.927273-100.072727 0l-372.363637 370.036364c-20.945455 20.945455-25.6 51.2-13.963636 76.8S111.709091 581.818182 141.963636 581.818182h37.236364v276.945454c0 53.527273 41.890909 95.418182 95.418182 95.418182h474.763636c53.527273 0 95.418182-41.890909 95.418182-95.418182v-88.436363c0-13.963636-9.309091-23.272727-23.272727-23.272728s-23.272727 9.309091-23.272728 23.272728v88.436363c0 25.6-20.945455 48.872727-48.872727 48.872728h-88.436363V670.254545c0-13.963636-9.309091-23.272727-23.272728-23.272727H386.327273c-13.963636 0-23.272727 9.309091-23.272728 23.272727V907.636364h-88.436363c-25.6 0-48.872727-20.945455-48.872727-48.872728V558.545455c0-13.963636-9.309091-23.272727-23.272728-23.272728H141.963636c-16.290909 0-20.945455-11.636364-23.272727-16.290909-2.327273-4.654545-4.654545-16.290909 4.654546-27.927273l372.363636-367.70909c9.309091-9.309091 25.6-9.309091 34.909091 0l370.036363 370.036363c11.636364 11.636364 6.981818 23.272727 4.654546 27.927273-2.327273 4.654545-6.981818 16.290909-23.272727 16.290909h-60.509091c-13.963636 0-23.272727 9.309091-23.272728 23.272727v97.745455c0 13.963636 9.309091 23.272727 23.272728 23.272727s23.272727-9.309091 23.272727-23.272727V581.818182h37.236364c30.254545 0 53.527273-16.290909 65.163636-44.218182 11.636364-25.6 6.981818-55.854545-13.963636-76.8z m-523.636364 232.727273h207.127273V907.636364h-207.127273v-214.109091z"
                                    fill="#b3fff6" p-id="9341"></path>
                            </svg>
                        </i>
                    </div>
                    <div class="text">主页</div>
                </a>
            </div>
            <!-- echarts -->
            <div class="icon-box" @click="emits('cameraFlight',1)">
                <a href="#">
                    <div class="layer">
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i class="fa">
                            <svg t="1717821818267" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="4174" width="200" height="200">
                                <path
                                    d="M734.35136 689.64352a290.95936 290.95936 0 0 0 60.17024-175.84128l-253.6448-2.64192 193.41312 178.4832h0.06144z m52.61312 48.5376a360.81664 360.81664 0 0 1-282.05056 135.35232C305.21344 873.53344 143.36 711.68 143.36 512S305.21344 150.46656 504.89344 150.46656c8.35584 0 16.65024 0.28672 24.86272 0.83968v42.00448a318.8736 318.8736 0 0 1 203.40736 97.0752l41.472-39.95648 9.64608 10.69056a374.00576 374.00576 0 0 1 95.8464 237.6704l0.512 14.47936-58.30656-0.34816a318.5664 318.5664 0 0 1-67.4816 195.60448l32.11264 29.65504z m-257.24928-251.904l181.0432-178.9952a290.97984 290.97984 0 0 0-181.0432-86.03648v265.03168zM501.94432 178.2784C319.03744 179.87584 171.17184 328.68352 171.17184 512c0 184.29952 149.42208 333.7216 333.7216 333.7216a332.8 332.8 0 0 0 243.4048-105.39008L501.39136 513.45408l0.55296-335.17568z m70.7584 305.2544l278.87616 1.76128a346.23488 346.23488 0 0 0-78.5408-194.6624l-200.33536 192.90112z m0 0"
                                    fill="#b3fff6" p-id="4175"></path>
                            </svg>
                        </i>
                    </div>
                    <div class="text">echarts</div>
                </a>
            </div>
            <!-- win10 -->
            <div class="icon-box" @click="emits('cameraFlight',2)">
                <a href="#">
                    <div class="layer">
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i class="fa">
                            <svg t="1717822021927" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="6060" width="200" height="200">
                                <path
                                    d="M99.39 783.66l339.14 55.25V545.45H99.39v238.21z m66.59-171.72h206.07v148.72l-206.07-33.5V611.94zM523.4 849.83l401.21 65.33v-369.7H523.4v304.37z m66.58-237.89h268.14v225.18l-268.14-43.69V611.94zM523.4 175.33v303.33h401.21V108.84L523.4 175.33z m334.72 236.84H590.09V231.62l268.14-44.43v224.97l-0.11 0.01zM99.39 478.65h339.14v-292.3l-339.14 56.2v236.1z m66.59-179.81l206.07-34.13v147.46H165.98V298.84z"
                                    p-id="6061" fill="#b3fff6"></path>
                            </svg>
                        </i>
                    </div>
                    <div class="text">win10</div>
                </a>
            </div>
            <!-- ai蛇 -->
            <div class="icon-box" @click="emits('cameraFlight',3)">
                <a href="#">
                    <div class="layer">
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i class="fa">
                            <svg t="1717822086199" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="8303" width="200" height="200">
                                <path
                                    d="M785.226752 604.542c-16.262144-6.806-31.921152-12.694-47.024128-17.726 15.721472-59.547 25.869312-127.997 1.514496-197.946C694.21056 258.155 514.843648 124.815 402.407424 115.667c-112.437248-9.142-175.348736 82.353-175.348736 82.353l79.601664 66.912c-1.518592 0.375-3.058688 0.735-4.649984 1.056-38.817792 7.839-74.96192-10.454-74.96192-10.454-53.541888-15.688-70.934528 3.924-70.934528 3.924 100.386816 18.298-12.049408 45.747-12.049408 45.747 49.528832 14.378 99.05152-20.909 99.05152-20.909l69.609472-5.228 17.39776 27.445L252.48768 365.34c34.809856 37.903 119.128064 70.58 168.656896 43.135 49.533952-27.453 155.272192-74.513 198.109184 5.228 27.275264 50.785 20.892672 79.302-3.245056 150.043-131.78368-2.183-208.193536 84.238-273.835008 202.881-64.077824 115.817-187.393024 32.685-187.393024 32.685s45.509632 60.131 149.914624 77.121c104.404992 16.983 140.544-125.492 198.104064-177.765 21.226496-19.278 48.641024-28.242 78.348288-30.772-6.564864 20.892-13.530112 44.082-20.79232 69.984-61.568 219.606 93.693952 205.218 199.442432 194.759C865.545216 922.184 1005.128704 696.574 785.226752 604.542zM403.736576 238.534c-8.22784-0.917-15.490048-0.894-22.10816-0.212-3.618816-16.799 3.320832-38.626 30.140416-37.69C449.247232 201.938 450.586624 243.767 403.736576 238.534zM765.145088 805.846c-40.153088 18.299-68.260864-16.984-61.568-88.876 0.910336-9.777 3.089408-20.555 6.049792-32.193 8.569856 2.396 16.851968 4.88 24.73472 7.35C805.303296 714.344 805.303296 787.545 765.145088 805.846z"
                                    fill="#b3fff6" p-id="8304"></path>
                            </svg>
                        </i>
                    </div>
                    <div class="text">AI蛇</div>
                </a>
            </div>

        </div>
    </div>
</template>
<script setup>

const emits = defineEmits(['cameraFlight'])

</script>
<style lang="scss" scoped>
.controlPage {
    width: 100%;
    height: 100%;
    position: relative;

    .rigtControlCont {
        pointer-events: auto;
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
        right: 10px;

        .icon-box {
            margin: 50px 0px;
        }

        .icon-box a {
            color: #fff;
            margin: 0 30px;
            text-decoration: none;
            display: block;
            /* 相对定位 */
            position: relative;
        }

        .icon-box a .layer {
            width: 60px;
            height: 60px;
            /* 动画过渡 */
            transition: 0.3s;
        }

        .icon-box a .layer i {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* 通过var函数调用自定义属性#12b7f5 */
            border: 1px solid #12b7f5;
            border-radius: 6px;
            transition: 0.3s;
        }

        .icon-box a .layer i.fa {
            line-height: 60px;

            .icon {
                margin: 10% 0% 0% 10%;
                width: 80%;
                height: 80%;
            }
        }

        .icon-box a .text {
            /* 绝对定位 */
            position: absolute;
            bottom: 0;
            opacity: 0;
            width: 100%;
            text-align: center;
            color: #b3fff6;
            /* 动画过渡 */
            transition: 0.3s;
        }

        .icon-box a:hover .text {
            /* 鼠标移入文本出现 */
            bottom: -35px;
            opacity: 1;
        }

        .icon-box a:hover .layer {
            /* 鼠标移入，该元素旋转-35度并倾斜20度 */
            transform: rotate(-35deg) skew(20deg);
        }

        /* 鼠标移入，设置图标外的每一层边框的样式（不透明度+位置偏移） */
        .icon-box a:hover .layer i:nth-child(1) {
            opacity: 0.2;
            transform: translate(0, 0);
        }

        .icon-box a:hover .layer i:nth-child(2) {
            opacity: 0.4;
            transform: translate(5px, -5px);
        }

        .icon-box a:hover .layer i:nth-child(3) {
            opacity: 0.6;
            transform: translate(10px, -10px);
        }

        .icon-box a:hover .layer i:nth-child(4) {
            opacity: 0.8;
            transform: translate(15px, -15px);
        }

        .icon-box a:hover .layer i:nth-child(5) {
            opacity: 1;
            transform: translate(20px, -20px);
        }

        /* 鼠标移入，设置每一层边框的阴影样式 */
        .icon-box a:hover .layer i {
            box-shadow: -1px 1px 3px #12b7f5;
        }


    }
}
</style>