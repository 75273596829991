<template>
  <div class="max-u-cont-son">
    <!-- 左侧区域 -->
    <div class="left-cont red">
      <!-- window 图标 -->
      <div class="left-icon-cont select-style red">
        <svg
          t="1695128343768"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="7696"
          width="200"
          height="200"
        >
          <path
            d="M490.666667 128v362.666667H128V128h362.666667z m0 768H128v-362.666667h362.666667V896z m42.666666-768H896v362.666667h-362.666667V128z m362.666667 405.333333V896h-362.666667v-362.666667H896z"
            p-id="7697"
            data-spm-anchor-id="a313x.search_index.0.i5.20233a816IUvHz"
            class=""
            fill="#0ca5f5"
          ></path>
        </svg>
      </div>
    </div>
    <!-- 右侧区域 -->
    <div class="right-cont red">
      <!-- <div class="right-icon-cont red"></div> -->
      <!-- 时间 -->
      <div class="right-time-cont select-style red">
        <div class="time-time red">
          {{ pageData.time.hours }}:{{ pageData.time.minutes }}
        </div>
        <div class="time-date red">
          {{ pageData.time.year }}/{{ pageData.time.month }}/{{
            pageData.time.day
          }}
        </div>
      </div>
      <!-- 右侧工具侧边栏图标 -->
      <div class="right-border-cont select-style red">
        <div class="red"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getTime } from "../../utils/getTime"; 
import { ref, reactive, onMounted, onUnmounted } from "vue";
// 定义页面需要数据
let pageData = reactive({
  // 时间
  time: {
    year: "0",
    month: "0",
    day: "0",
    hours: "0",
    minutes: "0",
  },
});
let getTimeTimer;
pageData.time = getTime();
onMounted(() => {
  getTimeTimer = setInterval(() => {
    pageData.time = getTime();
  }, 1000);
});
onUnmounted(() => {
  clearTimeout(getTimeTimer);
});
</script>
 <style lang="scss" scoped>
.max-u-cont-son {
  height: 100%;
  background-color: #f4d5ff2a;
  display: flex;
  justify-content: space-between;
  .left-cont {
    padding-left: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    .left-icon-cont {
      width: 32px;
      height: 32px;
      padding: 2px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .select-style:hover {
    cursor: pointer;
    border-radius: 4px;
    background-color: rgba(212, 212, 212, 0.212);

    svg {
      path {
        fill: #4ac0ff;
      }
    }
  }
  .right-cont {
    height: 100%;
    display: flex;
    align-items: center;
    color: #e6e6e6;
    letter-spacing: 1px;
    // 右侧时间
    .right-time-cont {
      padding: 5px 8px;
      text-align: center;
      font-size: 12px;
      margin-right: 10px;
      & :nth-child(2) {
        margin-top: 4px;
      }
    }
    // 右侧边 侧边栏
    .right-border-cont {
      background-color: #ffffffa4;
      width: 5px;
      height: 100%;
    }
  }
}
</style>
    