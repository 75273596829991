<template>
  <div ref="ref_desktop" class="desktop-cont red" @click="handleMouseDown">
    <!-- 行容器 -->
    <div v-for="(item, index) in pageData.mapCont" :key="`key_row_${index}`" class="desktop-row-cont red">
      <!-- 子项容器 -->
      <div v-for="itm in item" :key="`key_row_${itm.id}`" :row="itm.place.row" :list="itm.place.list" class="desktop-item-cont red" :draggable="itm.content != null ? true : false" :useId="itm.content != null ? itm.content.id : ''">
        <div v-if="itm.content != null" class="use-cont select-style" @dblclick="unpackDocument(itm.content.type)">
          <div class="use-icon-cont" v-html="itm.content.icon"></div>
          <div class="use-title-cont">{{ itm.content.title }}</div>
        </div>
      </div>
    </div>
    <!-- 右击后 展示工具栏 -->
    <div ref="ref_newFile_popup" v-show="pageData.newConstruction.show" :style="`position: absolute;top:${pageData.newConstruction.place.y}px;left:${pageData.newConstruction.place.x}px`" class="new-file-cont red">
      <div v-show="pageData.newConstruction.type == 1" class="file-item-cont red" @click.stop="newFileSetUp">
        新建文件夹
      </div>
      <div v-show="pageData.newConstruction.type == 2" class="file-item-cont red" @click.stop="rechristen">
        重命名
      </div>
      <div v-show="pageData.newConstruction.type == 2" class="file-item-cont red" @click.stop="delUse">
        删除
      </div>
    </div>
    <!-- win 弹框 -->
    <WinPopup :useData.sync="pageData.popup.showUseData" @close="closePop"></WinPopup>
    <!-- <div class="win-popup-cont red" v-if="pageData.popup.echarts.show">
      <div class="win-popup red"></div>
    </div> -->
  </div>
</template>  
  <script setup>
import { reactive, ref, onMounted, nextTick } from "vue";
import { svg_echarts, svg_folder } from "@/modules/winSystem/assets/js/iconArr";
// 弹出层
import WinPopup from "./components/popupWind.vue"

// 整个桌面
const ref_desktop = ref(null)
// 新建文件弹窗
const ref_newFile_popup = ref(null)
// 页面配置数据
let pageData = reactive({
  // 背景-应用数据
  mapCont: [],
  // 新建文件夹数据
  newConstruction: {
    useId: "",
    // 右击选中的文件的名称dom
    use_name_dom: {},
    // 是否展示
    show: false,
    // 类型 1 表示右击空白区域 进行新建文件夹 2 表示右击文件 删除和重命名
    type: 1,
    // 位置
    place: { x: null, y: null },
    // 目标 背景位置
    target: { row: null, y: null }
  },
  // win 弹窗数据
  popup: {
    showUseData: []
  }

});

let useArr = [
  {
    id: 'id_use_1',
    type: "echarts",
    place: { row: 0, list: 0 },
    title: "echarts",
    icon: svg_echarts,
  },
  {
    id: 'id_use_2',
    type: "file",
    place: { row: 0, list: 1 },
    title: "新建文件夹",
    icon: svg_folder,
  },
];
// 所有应用数量
// 预备下一个应用id
let getReadyId = useArr.length + 1
// 预备弹窗应用id 
let getPopUseId = 1
onMounted(() => {
  init();
});
// 初始化方法 设置背景
const init = () => {
  // 添加地图方格背景
  let rowData = [];
  for (let row = 0; row < 10; row++) {
    rowData.push([]);
    for (let list = 0; list < 25; list++) {
      rowData[row].push({
        id: `Id_${row + 1}_${list + 1}`,
        place: { row: row, list: list },
        content: null,
      });
    }
  }
  // 方格背景中添加应用
  useArr.forEach((item) => {
    rowData[item.place.row][item.place.list].content = {
      type: item.type,
      icon: item.icon,
      title: item.title,
      id: item.id
    };
  });
  pageData.mapCont = rowData;
  nextTick(() => {
    // 添加拖拽和右击事件
    let useContArr = document.getElementsByClassName("desktop-item-cont");
    addUseDrag(useContArr);
    rightClick(useContArr);
  });
};
// 添加应用拖拽事件
const addUseDrag = (useArrDom) => {
  // 开始id
  let startUseId = "";
  // 开始位置
  let startUsePlace = [];
  let endUseId = "";
  let endUsePlace = [];
  for (let i = 0; i < useArrDom.length; i++) {
    useArrDom[i].ondragstart = (e) => {
      if (e.currentTarget.getAttribute("draggable") === "true") {
        e.currentTarget.style.opacity = 0
        startUseId = e.currentTarget.getAttribute("useId")
        startUsePlace = [e.currentTarget.getAttribute("row"), e.currentTarget.getAttribute("list")]
      }
    };
    useArrDom[i].ondragover = (e) => {
      e.preventDefault();
    };
    useArrDom[i].ondragend = (e) => {
      e.currentTarget.style.opacity = 1
    };
    useArrDom[i].ondrop = (e) => {
      if (startUseId.length != 0) {
        endUseId = e.currentTarget.getAttribute("useId")
        if (endUseId != startUseId) {
          endUsePlace = [e.currentTarget.getAttribute("row"), e.currentTarget.getAttribute("list")]
          useArr.forEach(item => {
            if (item.id == endUseId) {
              item.place = { row: startUsePlace[0], list: startUsePlace[1] }
            } else if (item.id == startUseId) {
              item.place = { row: endUsePlace[0], list: endUsePlace[1] }
            }
          })
          init()
        }
      }
    };
  }
};
// 2.鼠标右键事件
// 2.1 鼠标右键显示弹出层 作用:计算弹出位置 && 显示
const rightClick = (useArrDom) => {
  for (let i = 0; i < useArrDom.length; i++) {
    useArrDom[i].oncontextmenu = (e) => {
      let row = useArrDom[i].getAttribute('row')
      let list = useArrDom[i].getAttribute('list')
      e.preventDefault();
      const use = e.currentTarget;
      // use_draggable 为 true  表示当前位置可以创建 false 表示位置已占用右击的是文件
      const use_draggable = use.getAttribute("draggable") === "true" ? false : true;
      const use_row = use.getAttribute("row");
      const use_list = use.getAttribute("list");
      const use_Id = use.getAttribute("useId");
      // 桌面大小
      // console.log("clientWidth",clientWidth)
      const desktopSize = [ref_desktop.value.offsetWidth, ref_desktop.value.offsetHeight]
      // 新文件弹出
      const newFilePopupSize = [ref_newFile_popup.value.offsetWidth, ref_newFile_popup.value.offsetHeight]
      let popupPosition = [Number(list) * 80, Number(row) * 101]
      if (Number(list) * 80 + newFilePopupSize[0] > desktopSize[0]) {
        popupPosition[0] = Number(list) * 80 - newFilePopupSize[0]
      }
      if (Number(row) * 101 + newFilePopupSize[1] > desktopSize[1]) {
        popupPosition[1] = Number(row) * 101 - newFilePopupSize[1]
      }
      pageData.newConstruction.place = { x: popupPosition[0], y: popupPosition[1] }
      pageData.newConstruction.target = { row: use_row, list: use_list }
      pageData.newConstruction.useId = use_Id
      if (use_draggable) {
        // 右击 的是空白区域,新建文件夹
        pageData.newConstruction.type = 1
      } else {
        pageData.newConstruction.type = 2
        pageData.newConstruction.use_name_dom = e.currentTarget.getElementsByClassName("use-title-cont")[0]
      }
      pageData.newConstruction.show = true
    }
  }
};
// 2.2 点击了新建文件夹事件 在当前位置新增文件夹 
const newFileSetUp = () => {
  // 生成id
  const useId = "id_use_" + getReadyId
  getReadyId++
  useArr.push({
    id: useId,
    type: "file",
    icon: svg_folder,
    title: "新建文件夹",
    place: pageData.newConstruction.target
  })
  pageData.newConstruction.show = false
  init()
}
// 2.3 点击了删除事件 删除当前所选项
const delUse = () => {
  let filteredData = useArr.filter(item => item.id !== pageData.newConstruction.useId);
  useArr = filteredData
  pageData.newConstruction.show = false
  init()
}
// 2.4 点击了重命名 重新命名
const rechristen = () => {
  const oldName = JSON.parse(JSON.stringify(pageData.newConstruction.use_name_dom.innerHTML))
  pageData.newConstruction.use_name_dom.innerHTML = `<input id='newNameInput' type='text' value=${oldName}  autocomplete='off'/>`
  const newNameInput = document.getElementById('newNameInput')
  newNameInput.focus();
  newNameInput.select();
  // 文本框失去焦点 修改名称 并且刷新页面
  newNameInput.addEventListener("blur", () => {
    const rightClickUse = useArr.find(item => { return item.id == pageData.newConstruction.useId })
    rightClickUse.title = newNameInput.value
    pageData.newConstruction.use_name_dom.innerText = newNameInput.value
  });
  // 按下回车键 触发失去焦点事件
  newNameInput.addEventListener("keydown", (event) => {
    if (event.keyCode === 13 || event.key === "Enter") {
      // 在这里编写按下回车键时的操作逻辑
      newNameInput.blur()
    }
  });
  pageData.newConstruction.show = false
}
// 双击打开文件夹
const unpackDocument = (type) => {
  pageData.popup.showUseData.push({
    type: type,
    show: true,
    id: 'pop_' + getPopUseId
  })
  getPopUseId++
}
// 监听按下鼠标 关闭右击新建文件夹弹窗
const handleMouseDown = () => {
  console.log("监听按下鼠标")
  pageData.newConstruction.show = false
}
// 接受组件传出方法
const closePop = (id) => {
  pageData.popup.showUseData.forEach((item, index) => {
    if (item.id == id) {
      pageData.popup.showUseData.splice(index, 1)
    }
  })
}
</script>
  <style lang="scss" scoped>
.desktop-cont {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  .desktop-row-cont {
    flex: 1;
    display: flex;
    justify-content: space-between;

    .desktop-item-cont {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;

      .use-cont {
        width: 70px;
        height: 80px;

        .use-icon-cont {
          width: 50px;
          margin: 0 auto;
          margin-top: 5px;
          ::v-deep svg {
            height: 55px;
            width: 100%;
          }
        }
        .use-title-cont {
          margin: 0px auto;
          margin-top: -6px;
          font-size: 12px;
          height: 20px;
          color: azure;
          text-align: center;
          overflow: hidden;
          white-space: nowrap; /* 文字不换行 */
          // text-overflow: ellipsis;
          width: 90%;
          .newNameInput {
            width: 100%;
          }
        }
      }
      .select-style:hover {
        cursor: pointer;
        border-radius: 4px;
        background-color: rgba(212, 212, 212, 0.212);
        svg {
          path {
            fill: #4ac0ff;
          }
        }
      }
    }
  }
  // 新建文件
  .new-file-cont {
    width: 150px;
    background-color: #dddddd;
    box-shadow: 1px 1px 20px 0px rgb(0, 0, 0);
    border-radius: 10px;
    padding: 5px 0px;
    overflow: hidden;
    .file-item-cont {
      padding: 5px 10px;
      font-size: 14px;
      letter-spacing: 1px;
      &:hover {
        cursor: pointer;
        background-color: #cacaca;
      }
    }
  }
  // win 弹窗
  .win-popup-cont {
    width: 1000px;
    height: 600px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 0 6px 3px rgba(68, 68, 68, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}



</style>