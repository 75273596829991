<template>
  <router-view />
</template>
<style lang="scss">
@font-face {
  font-family: "nubText";
  src: url("./assets/text/LCD-BQ.TTF");
}
@font-face {
  font-family: "titleText";
  src: url("./assets/text/TITLE.TTF");
}

html {
  margin: 0px;
  padding: 0px;
  border: 0px;
  background-color: #000;
}
body {
  margin: 0px;
  padding: 0px;
  border: 0px;
}
div {
  box-sizing: border-box;
}
.red {
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  input:focus {
    outline: none;
    /* 去掉输入框的默认边框 */
  }

  .el-color-picker__panel.echarts-color-picker {
    background-color: #87878742;

    .el-button.is-text {
      color: rgb(255, 35, 35);
    }
  }

  .el-popper.is-light {
    border: 1px solid #7bc2ff;
  }

  .el-select__popper {
    background-color: #87878742 !important;
    .el-select-dropdown__item {
      color: #65efff;

      &:hover {
        background-color: #65baff2f;
      }
    }
    .el-select-dropdown__item.hover {
      background-color: #65baff2f;
    }
  }
}
</style>
