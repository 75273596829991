<template>
  <div class="win-popup-cont red" @mousedown="zIndexTop" v-for="item in props.useData" :key="item.id">
    <!-- 文件弹窗 -->
    <div class="win-popup red" v-if="item.type == 'file' && !useShowArr.echarts">
      <!-- 表头 -->
      <div class="popup-top-cont popup-top-drag red">
        <div class="popup-top-left-cont red">
          <div class="popup-tab-name-cont red">
            <div class="popup-tab-left-cont red">
              <div class="tab-icon red" v-html="svg_folder"></div>
              <div class="tab-name red">新建文件夹</div>
            </div>
            <div class="popup-tab-right-cont red" v-html="svg_close"></div>
          </div>
          <div class="popup-add-icon-cont red" v-html="svg_add_a"></div>
        </div>
        <div class="popup-top-right-cont red">
          <div class="window_control" v-html="svg_reduce"></div>
          <div class="window_control" v-html="svg_full_screen"></div>
          <div class="window_control" v-html="svg_close" @click="emits('close', item.id)"></div>
        </div>
      </div>
      <!-- 工具栏 -->
      <div class="popup-toolbar-cont red">
        <!-- 新建 -->
        <div class="tool-style-a red">
          <div class="tool-style-a-icon" v-html="svg_add_b"></div>
          <div class="tool-style-a-name">新建</div>
          <div class="tool-style-a-icon-r" v-html="svg_downward"></div>
        </div>
        <!-- 分割线 -->
        <div class="halving-line red"></div>
        <!-- 样式二 -->
        <div class="tool-style-b red">
          <div class="tool-style-b-icon red" v-html="svg_shear"></div>
        </div>
        <div class="tool-style-b red">
          <div class="tool-style-b-icon red" v-html="svg_copy"></div>
        </div>
        <div class="tool-style-b red">
          <div class="tool-style-b-icon red" v-html="svg_stick"></div>
        </div>
        <div class="tool-style-b red">
          <div class="tool-style-b-icon red" v-html="svg_rechristen"></div>
        </div>
        <div class="tool-style-b red">
          <div class="tool-style-b-icon red" v-html="svg_share"></div>
        </div>
        <div class="tool-style-b red">
          <div class="tool-style-b-icon red" v-html="svg_del"></div>
        </div>
        <div class="halving-line red"></div>
        <!-- 排序 -->
        <div class="tool-style-a red">
          <div class="tool-style-a-icon" v-html="svg_sort"></div>
          <div class="tool-style-a-name">排序</div>
          <div class="tool-style-a-icon-r" v-html="svg_downward"></div>
        </div>
        <div class="tool-style-a red">
          <div class="tool-style-a-icon" v-html="svg_examine"></div>
          <div class="tool-style-a-name">查看</div>
          <div class="tool-style-a-icon-r" v-html="svg_downward"></div>
        </div>
        <div class="halving-line red"></div>
        <div class="tool-style-b red">
          <div class="tool-style-b-icon red" v-html="svg_more"></div>
        </div>
      </div>
      <!-- 目录和搜索栏 -->
      <div class="popup-catalogue-search-cont red">
        <!-- 左侧图标 -->
        <div class="left-icon-arr-cont red">
          <div class="icon-item-cont" v-html="svg_left"></div>
          <div class="icon-item-cont" v-html="svg_right"></div>
          <div class="icon-item-cont" v-html="svg_downward"></div>
          <div class="icon-item-cont" v-html="svg_top"></div>
        </div>
        <!-- 中间区域 -->
        <div class="catalogue-cont red">
          <div class="catalogue-left-cont">
            <div class="letf-file-icon" v-html="svg_folder"></div>
            <div class="letf-right-min-icon" v-html="svg_min_right"></div>
            <div class="text-name-text">新建文件夹</div>
          </div>
          <div class="catalogue-right-cont red">
            <div class="bottom-icon red" v-html="svg_downward"></div>
            <div class="refresh-icon red" v-html="svg_refresh"></div>
          </div>
        </div>
        <!-- 右侧搜索 -->
        <div class="search-cont red">

        </div>
      </div>
      <!-- 主体区域 -->
      <div class="popup-mainbody-cont red">
        <!-- 左侧目录 -->
        <div class="mainbody-left-catalogue-cont red">
          <!-- 目录结构容器  待开发-->
          <div class="catalogue-father-cont red">
            <!-- 父级容器 -->
            <div class="catalogue-father-title red">
              <div class="father-title-spread red">
              </div>
              <div class="father-title-icon red">
              </div>
              <div class="father-title-text red">快速访问</div>
            </div>
            <!-- 子级容器 -->
            <div class="catalogue-son-cont red">

            </div>
          </div>
        </div>
        <!-- 右侧内容区 -->
        <div class="mainbody-right-core-cont red">{{ item.type }}</div>
      </div>
      <!-- 底部区域 -->
      <div class="popup-bottom-statistics-cont red"></div>

    </div>
  </div>
  <!-- echarts app弹窗 -->
  <!-- <div class="win-popup-echarts-cont red" v-if="useShowArr.echarts"> -->
  <div class="win-popup-echarts-cont red" v-if="useShowArr.echarts">
    <echartsUse @close="echartsUseClose"></echartsUse>
  </div>
</template>
<script setup>
// echarts 应用
import echartsUse from './echarts'
import { defineProps, reactive, watch, nextTick, defineEmits } from 'vue'
import { svg_folder, svg_min_right, svg_close, svg_add_a, svg_add_b, svg_downward, svg_refresh, svg_reduce, svg_full_screen, svg_shear, svg_copy, svg_rechristen, svg_stick, svg_share, svg_del, svg_sort, svg_examine, svg_more, svg_left, svg_right, svg_top } from "@/modules/winSystem/assets/js/iconArr";
const props = defineProps({
  // 渲染所有弹窗数据
  useData: {
    type: Array,
    default: []
  }
})
let useShowArr = reactive({
  echarts: false
})
// 层级置顶
let zIndexValue = 2
// 弹窗数据发生改变 重载拖拽功能 放大 关闭功能
watch(props.useData, (newValue, oldValue) => {
  // 判断是否显示应用
  const hasEcharts = newValue.some(obj => obj.type.includes("echarts"));
  useShowArr.echarts = hasEcharts
  // DOM 更新后 重新渲染新增窗口 以及重新绑定拖拽事件
  nextTick(() => {
    addDragIncident()
  })
})

// 子组件触发 echarts 弹窗关闭 
const echartsUseClose = () => {
  const echartsUse = props.useData.find(item => {
    return item.type == 'echarts'
  })
  emits('close', echartsUse.id)
}

// 开始拖拽
const addDragIncident = () => {
  // 开始拖拽需要参数
  let startDragging = {
    startPlace: [],
    targetDom: null,
    targetDomPlace: null
  }
  let popupContArr = document.getElementsByClassName("popup-top-drag")
  // 双击打开的最后一个文件 层级拉升到最高
  const winPopupContDom = document.getElementsByClassName("win-popup-cont")
  if (winPopupContDom.length) {
    Array.from(winPopupContDom).at(-1).style.zIndex = zIndexValue
    zIndexValue++
  }
  // 开始循环 添加 拖拽事件
  popupContArr = Array.from(popupContArr)
  popupContArr.forEach((item, index) => {
    item.addEventListener('mousedown', (event) => {
      startDragging.startPlace = [event.clientX, event.clientY]
      startDragging.targetDom = item.parentNode.parentNode
      startDragging.targetDomPlace = [startDragging.targetDom.offsetLeft, startDragging.targetDom.offsetTop]
      document.addEventListener('mousemove', resizeDiv)
      document.addEventListener('mouseup', stopResizeDiv)
    })
  });
  // 拖拽中调整实时位置
  const resizeDiv = (event) => {
    let winPopupDom = document.getElementsByClassName("win-popup")
    let power = 1000 / winPopupDom[0].getBoundingClientRect().width
    startDragging.targetDom.style.top = (event.clientY - startDragging.startPlace[1]) * power + startDragging.targetDomPlace[1] + 'px'
    startDragging.targetDom.style.left = (event.clientX - startDragging.startPlace[0]) * power + startDragging.targetDomPlace[0] + 'px'
  }
  // 松开鼠标 拖拽结束 删除事件
  const stopResizeDiv = () => {
    document.removeEventListener('mousemove', resizeDiv)
    document.removeEventListener('mouseup', stopResizeDiv)
  }
}
// 鼠标按下后层级拉升到最高
const zIndexTop = (e) => {
  e.currentTarget.style.zIndex = zIndexValue
  zIndexValue++
}

const emits = defineEmits(['close']);
</script>

<style lang="scss" scoped>
// win 弹窗
.win-popup-cont {
  position: absolute;
  top: 180px;
  left: 460px;
  overflow: hidden;

  .win-popup {
    width: 1000px;
    height: 600px;
    border-radius: 15px;
    box-shadow: 0 0 6px 3px rgba(68, 68, 68, 0.5);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .popup-top-cont {
      background-color: #e8e8e8;
      height: 40px;
      display: flex;
      justify-content: space-between;

      .popup-top-left-cont {
        height: 100%;
        display: flex;
        align-items: flex-end;

        .popup-tab-name-cont {
          background-color: #f8f8f8;
          height: 32px;
          width: 240px;
          margin-left: 10px;
          padding-left: 8px;
          border-radius: 5px 5px 0px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .popup-tab-left-cont {
            display: flex;

            .tab-icon {
              display: flex;
              align-items: center;

              ::v-deep svg {
                width: 20px;
                height: 18px;
              }
            }

            .tab-name {
              margin-left: 7px;
              font-size: 12px;
              font-weight: bold;
            }
          }

          .popup-tab-right-cont {
            margin-right: 12px;

            ::v-deep svg {
              width: 13px;
              height: 13px;
            }
          }
        }

        .popup-add-icon-cont {
          height: 30px;
          display: flex;
          align-items: center;
          padding: 0px 5px;
          margin-left: 10px;

          &:hover {
            cursor: pointer;
            border-radius: 5px;
            background-color: #a1a1a125;
          }

          ::v-deep svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .popup-top-right-cont {
        display: flex;

        .window_control {
          width: 43px;
          height: 30px;
          margin-right: 2px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:nth-child(3) {
            margin-right: 0px;

            &:hover {
              cursor: pointer;
              background-color: #df2c1c;
            }
          }

          &:hover {
            cursor: pointer;
            background-color: #a1a1a125;
          }

          ::v-deep svg {
            width: 13px;
            height: 13px;
          }
        }
      }
    }

    .popup-toolbar-cont {
      height: 55px;
      background-color: #f8f8f8;
      display: flex;
      align-items: center;
      padding-left: 12px;
      border-bottom: 1px solid rgb(219, 219, 219);

      .tool-style-a {
        height: 35px;
        display: flex;
        align-items: center;

        &:hover {
          border-radius: 2px;
          cursor: pointer;
          background-color: #a1a1a125;
        }

        .tool-style-a-icon {
          display: flex;
          align-items: center;
          margin-left: 8px;

          ::v-deep svg {
            width: 16px;
            height: 16px;
          }
        }

        .tool-style-a-name {
          margin-left: 7px;
          font-size: 12px;
        }

        .tool-style-a-icon-r {
          margin-left: 3px;
          margin-right: 8px;
          display: flex;
          align-items: center;

          ::v-deep svg {
            width: 12px;
            height: 12px;
          }
        }
      }

      .tool-style-b {
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0px 7px;
        margin: 0px 5px;

        &:hover {
          border-radius: 2px;
          cursor: pointer;
          background-color: #a1a1a125;
        }

        &:nth-child(3) {
          margin: 0px 5px 0px 0px;
        }

        &:nth-child(8) {
          margin: 0px 0px 0px 5px;
        }

        &:nth-child(13) {
          margin: 0px;
        }

        .tool-style-b-icon {
          display: flex;
          align-items: center;

          ::v-deep svg {
            width: 18px;
            height: 18px;
          }
        }
      }

      .halving-line {
        height: 100%;
        height: 32px;
        border-right: 1px solid rgb(231, 231, 231);
        margin: 0px 6px;
      }
    }

    .popup-catalogue-search-cont {
      height: 53px;
      background-color: #f8f8f8;
      display: flex;
      align-items: center;

      .left-icon-arr-cont {
        height: 35px;
        width: 140px;
        display: flex;
        justify-content: space-between;

        .icon-item-cont {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            border-radius: 2px;
            cursor: pointer;
            background-color: #a1a1a125;
          }

          ::v-deep svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      .catalogue-cont {
        margin-left: 3px;
        border: 1px solid rgb(211, 211, 211);
        height: 35px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .catalogue-left-cont {
          display: flex;
          align-items: center;

          .letf-file-icon {
            height: 16px;
            margin-left: 8px;

            ::v-deep svg {
              width: 16px;
              height: 100%;
            }
          }

          .letf-right-min-icon {
            margin: 0px 8px;

            ::v-deep svg {
              width: 8px;
              height: 8px;
            }
          }

          .text-name-text {
            font-size: 12px;
          }
        }

        .catalogue-right-cont {
          height: 100%;
          display: flex;

          .bottom-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 35px;

            &:hover {
              border-radius: 2px;
              cursor: pointer;
              background-color: #a1a1a125;
            }

            ::v-deep svg {
              width: 14px;
              height: 14px;
            }
          }

          .refresh-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 35px;

            &:hover {
              border-radius: 2px;
              cursor: pointer;
              background-color: #a1a1a125;
            }

            ::v-deep svg {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .search-cont {
        height: 35px;
        width: 205px;
        margin: 0px 10px;
      }
    }

    .popup-mainbody-cont {
      flex: 1;
      background-color: #f8f8f8;
      display: flex;

      .mainbody-left-catalogue-cont {
        width: 154px;
        border-right: 1px solid rgb(226, 226, 226);
      }

      .mainbody-right-core-cont {
        flex: 1;
      }
    }

    .popup-bottom-statistics-cont {
      background-color: #f8f8f8;
      height: 35px;
    }
  }
}

.win-popup-echarts-cont {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
</style>