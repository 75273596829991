<template>
  <div class="pageCont red">
    <!-- 加载页面 -->
    <div ref="Ref_loadPageCont" v-if="!pageConf.loadStateAnimation" class="loadPageCont red">
      <loadPage :loadState="pageConf.loadState" @close="loadAccomplish"></loadPage>
    </div>
    <!-- 控制组件 -->
    <div v-else class="controlAssembly">
      <control @cameraFlight="cameraFlight"></control>
    </div>
    <!-- three 场景dom -->
    <div ref="mountDom" class="pageCont_scene red"> </div>
    <!-- ecahrts 大屏dom -->
    <div v-show="false" ref="Ref_displayDOM" class="displayDOM">
      <winSystem></winSystem>
    </div>
    <!-- echarts 代码生成器dom -->
    <div v-show="false" ref="Ref_outputDom" class="displayDOM">
      <echartsOutput></echartsOutput>
    </div>
    <!-- echarts 代码配置dom -->
    <div v-show="false" ref="Ref_allocationDom" class="displayDOM">
      <echartsAllocation></echartsAllocation>
    </div>
    <!-- 底部悬挂备案号 -->
    <div class="icpNumberCont" v-if="pageConf.loadStateAnimation">
      <a href="https://beian.miit.gov.cn/" target="_blank">冀ICP备2024065258号-1</a>
    </div>
  </div>
</template>

<script setup>
import * as THREE from "three";
import TWEEN from '@tweenjs/tween.js';
import loadPage from "@/modules/desktop/modules/loadPage/index.vue"               // 加载页面
import winSystem from "@/modules/winSystem"               // win桌面 dom
import ecahrtsComp from "@/modules/echartsCreate"               // 大屏 ecahrts dom
import echartsOutput from "./components/echartsOutput.vue"               // 代码输出器 dom
import echartsAllocation from "@/modules/echartsCreate/index.vue"               // echatrs配置控制器 dom
import control from "@/modules/desktop/modules/control/index.vue"               // 右侧相机飞行按钮
import { ref, reactive, onMounted } from "vue";
import { coordinates, establishLight, establishCamera, creaRendererGl, creaRendererCSS, cameraChange, toLoadModel, insertDOM } from "./utils/sceneInit";  // 场景初始化
let sceneGL, sceneDOM, camera, rendererGL, rendererCSS, controls;            // 场景 / 相机 / 渲染器 / 模型加载器 
let Ref_loadPageCont = ref(null)                      // 加载动画
let mountDom = ref(null)                              // three 场景挂载dom
let Ref_displayDOM = ref(null)                        // echarst挂载dom
let Ref_outputDom = ref(null)                         // echarst代码输出器挂载dom
let Ref_allocationDom = ref(null)                         // echarst代码输出器挂载dom
const pageConf = reactive({                           // 页面配置文件
  loadState: false,                                    // 加载是否完成
  loadStateAnimation: false,
  echarts: {
    show: false
  },
  echartsOut: {
    show: false
  },
  timeShow: false

})
onMounted(() => {
  Init()
})
// 场景初始化
const Init = async () => {
  sceneGL = new THREE.Scene();
  // sceneGL.fog = new THREE.Fog(0x000000,14000,15000)			// 颜色值 , 近端不受影响距离 ，结束位置外全被雾效覆盖
  sceneDOM = new THREE.Scene();    //0x000000
  coordinates(sceneGL)                                                  // 坐标轴
  camera = establishCamera(mountDom)                                  // 创建相机
  rendererGL = creaRendererGl(mountDom)               // 创建渲染器
  // establishLight(sceneGL,rendererGL)                                               // 创建光源
  rendererCSS = creaRendererCSS(rendererGL, mountDom)               // 创建渲染器
  controls = cameraChange(controls, camera, sceneGL, rendererGL, sceneDOM, rendererCSS)
  await toLoadModel(sceneGL)                          // 渲染模型
  insertDOM(sceneGL.getObjectByName("win10dom"), sceneDOM, Ref_displayDOM)                     // 插入dom    
  insertDOM(sceneGL.getObjectByName("echatrs输出dom"), sceneDOM, Ref_outputDom)                    // 插入dom                         
  insertDOM(sceneGL.getObjectByName("echarts配置dom"), sceneDOM, Ref_allocationDom)                    // 插入dom                         

  rendererGL.render(sceneGL, camera);
  rendererCSS.render(sceneDOM, camera);

  window.onresize = () => {                                           // 窗口尺寸变化
    rendererGL.setSize(mountDom.value.clientWidth, mountDom.value.clientHeight);
    rendererCSS.setSize(mountDom.value.clientWidth, mountDom.value.clientHeight);
    camera.aspect = mountDom.value.clientWidth / mountDom.value.clientHeight;
    camera.updateProjectionMatrix();
    rendererGL.render(sceneGL, camera);
    rendererCSS.render(sceneDOM, camera);
  };

  // 添加点击事件
  let raycaster = new THREE.Raycaster();
  let mouse = new THREE.Vector2();
  const onMousemove = (event) => {
    event.preventDefault();
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    // 通过射线投射器获取鼠标点击位置的交叉点
    raycaster.setFromCamera(mouse, camera);
    let intersects = raycaster.intersectObjects(sceneGL.children, true);
    if (intersects.length > 0) {
      // 点击到了对象   
      let clickedObject = intersects[0].object;
      let domNameArr = ['win10dom', 'echarts代码输出dom', 'echarts配置dom', '事件记录dom', '贪吃蛇dom', '贪吃蛇dom']
      if (domNameArr.indexOf(clickedObject.name) != -1) {
        rendererGL.domElement.style.pointerEvents = 'none';
      } else {
        rendererGL.domElement.style.pointerEvents = 'auto';
      }
    } else {
      rendererGL.domElement.style.pointerEvents = 'auto';
    }
  }
  window.addEventListener('mousemove', onMousemove);
  function render() {
    TWEEN.update();
    rendererGL.render(sceneGL, camera);
    rendererCSS.render(sceneDOM, camera);
    requestAnimationFrame(render);
  }
  render()

  Ref_loadPageCont.value.classList.add('loadPageContClose')
  pageConf.loadState = true
  console.log("模型加载完毕")

}
// 加载完成
const loadAccomplish = () => {
  Ref_loadPageCont.value.classList.add('loadVanish')
  setTimeout(() => { pageConf.loadStateAnimation = true }, 2000);

}
// 相机飞行
const cameraFlight = (e) => {
  console.log("cameraFlight", e)
  var initialTarget = new THREE.Vector3();
  camera.getWorldDirection(initialTarget);
  console.log("enabledenabled", controls)
  controls.enabled = false;
  if (e == 0) {
    controls.enabled = true;
    // 相机恢复原位
    new TWEEN.Tween(camera.position).to({ x: -5530, y: 6067, z: 22000 }, 1000).start()
    new TWEEN.Tween(initialTarget).to({ x: 1000, y: 2000, z: 0 }, 1000).onUpdate(() => { camera.lookAt(initialTarget) }).start()
  } else if (e == 1) {
    // 相机飞行到 echarts 控制器
    new TWEEN.Tween(camera.position).to({ x: -346, y: 5174, z: 3544 }, 1000).start()
    new TWEEN.Tween(initialTarget).to({ x: -346, y: 2000, z: 2000 }, 1000).onUpdate(() => { camera.lookAt(initialTarget) }).start()
  } else if (e == 2) {
    // 相机飞行到 win10 屏幕
    new TWEEN.Tween(camera.position).to({ x: -387, y: 1000, z: 3000 }, 1000).start()
    new TWEEN.Tween(initialTarget).to({ x: -387, y: 1000, z: 0 }, 1000).onUpdate(() => { camera.lookAt(initialTarget) }).start()

  } else if (e == 3) {
    // 相机飞行到 a* 蛇 屏幕
    new TWEEN.Tween(camera.position).to({ x: 2850, y: 4200, z: 7800 }, 1000).start()
    new TWEEN.Tween(initialTarget).to({ x: 2850, y: 4200, z: 5000 }, 1000).onUpdate(() => { camera.lookAt(initialTarget) }).start()

  }


}
</script>


<style lang="scss" scoped>
.pageCont {
  .loadPageCont {
    padding: 30px;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    z-index: 99;
    background-color: rgba(0, 0, 0, 1);
    overflow: hidden;
  }

  .loadVanish {
    transition: 2s;
    filter: blur(20px);
    background-color: rgba(0, 0, 0, 0);
  }

  .controlAssembly {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    z-index: 99;
    overflow: hidden;
    pointer-events: none;
  }

  .loadPageContClose {
    animation: slowClear 7s linear;
    animation-fill-mode: forwards;
  }

  @keyframes slowClear {
    0% {
      opacity: 1;
    }

    30% {
      opacity: 1;
    }

    100% {
      opacity: 0.7;

    }
  }

  .pageCont_scene {
    width: 100vw;
    height: 100vh;

    .displayDOM {
      overflow: hidden;
    }
  }
}

.icpNumberCont {
  position: absolute;
  z-index: 50;
  bottom: 10px;
  text-align: center;
  width: 100%;

  a {
    color: rgb(186 247 255) !important;
  }
}
</style>