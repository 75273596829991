<!-- echarts 配置界面 -->
<template>
    <div class="echarts-max-u-cont red">
        <!-- 左侧配置项 -->
        <div class="charts-left red">
            <!-- echarts 图 容器 -->
            <div class="charts-left-picture-cont red">
                <div id="echartsCont" class="charts-left-picture red"></div>
            </div>
            <!-- 一级配置项 容器 -->
            <div class="charts-left-configuration-cont red">
                <!-- 配置项子项 -->
                <div class="charts-left-configuration-item red" v-for="item in dataArr.configuration.settingUp"
                    @click="item.show = !item.show">
                    <div>{{ item.name }}</div>
                    <div :class="{ open: item.show }"></div>
                </div>
            </div>
        </div>
        <!-- 右侧区域 配置项-->
        <div class="charts-right-cont red">
            <div class="charts-right red">
                <!-- 最大配置项容器 -->
                <div class="charts-right-item-cont red">
                    <!-- 标题配置 -->
                    <div class="charts-right-item-title-cont red">
                        <div>
                            标题
                        </div>
                        <div></div>
                    </div>
                    <!-- 内容区域 -->
                    <div class="charts-right-item-content-cont red">
                        <!-- 二级容器 -->
                        <div class="charts-item-content-second-level-cont red">
                            <div class="charts-second-level-cont-title red">
                                <div></div>
                                <div>二级容器标题</div>
                            </div>
                            <div class="charts-second-level-content-cont red">
                                <!-- 配置项 -->
                                <div class="charts-item">
                                    <div class="charts-item-col">
                                        <div class="charts-item-name">
                                            开关：
                                        </div>
                                        <div class="charts-item-value">
                                            <div class="charts-item-value-btn">
                                                <label>
                                                    <!-- checked 默认选中属性 -->
                                                    <input type="checkbox" v-model="dataArr.template.switch" hidden>
                                                    <span></span>
                                                    <i class="indicator"></i>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="charts-item-name">
                                            开关：
                                        </div>
                                        <div class="charts-item-value">
                                            <div class="charts-item-value-btn">
                                                <label>
                                                    <!-- checked 默认选中属性 -->
                                                    <input type="checkbox" v-model="dataArr.template.switch" hidden>
                                                    <span></span>
                                                    <i class="indicator"></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="charts-item">
                                    <div class="charts-item-col">
                                        <div class="charts-item-name">
                                            输入框：
                                        </div>
                                        <div class="charts-item-value">
                                            <el-input v-model="dataArr.template.textInput" placeholder="请输入" />
                                        </div>
                                    </div>
                                </div>
                                <div class="charts-item">
                                    <div class="charts-item-col">
                                        <div class="charts-item-name">
                                            文本域：
                                        </div>
                                        <div class="charts-item-value">
                                            <el-input v-model="dataArr.template.textInput"
                                                :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"
                                                placeholder="Please input" />
                                        </div>
                                    </div>

                                </div>

                                <div class="charts-item">
                                    <div class="charts-item-col">
                                        <div class="charts-item-name">
                                            数字框：
                                        </div>
                                        <div class="charts-item-value">
                                            <el-input-number v-model="dataArr.template.numberInput" :min="1" :max="10" />
                                        </div>
                                    </div>
                                </div>
                                <div class="charts-item">
                                    <div class="charts-item-col">

                                        <div class="charts-item-name">
                                            取色器：
                                        </div>
                                        <div class="charts-item-value">
                                            <el-color-picker v-model="dataArr.template.color" show-alpha
                                                popper-class="echarts-color-picker" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <template v-for="(item, key) in dataArr.configuration.settingUp">
                    <!-- 标题配置 -->
                    <div class="charts-right-item-cont red" v-if="item.show">
                        <div class="charts-right-item-title-cont red">
                            <div>
                                {{ item.name }}
                            </div>
                            <div></div>
                        </div>
                        <!-- 内容区域 -->
                        <div class="charts-right-item-content-cont red">
                            <!-- 全局配置 -->
                            <div class="charts-item-content-second-level-cont red" v-if="item.name == '全局配置'">
                                <div class="charts-second-level-cont-title red">
                                    <div></div>
                                    <div>全局配置</div>
                                </div>
                                <div class="charts-second-level-content-cont red">
                                    <!-- 配置项 -->
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                尺寸：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-input-number v-model="item.size[0]" :min="0" :max="200" />
                                            </div>
                                            <div class="charts-item-value margin-left-10">
                                                <el-input-number v-model="item.size[1]" :min="0" :max="200" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                位置：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-input-number v-model="item.location[0]" :min="0" :max="200" />
                                            </div>
                                            <div class="charts-item-value margin-left-10">
                                                <el-input-number v-model="item.location[1]" :min="0" :max="200" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 标题配置 -->
                            <div class="charts-item-content-second-level-cont red" v-if="item.name == '标题配置'">
                                <div class="charts-second-level-cont-title red">
                                    <div></div>
                                    <div>容器配置</div>
                                </div>
                                <div class="charts-second-level-content-cont red">
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                显示：
                                            </div>
                                            <div class="charts-item-value">
                                                <div class="charts-item-value-btn">
                                                    <label>
                                                        <!-- checked 默认选中属性 -->
                                                        <input type="checkbox" v-model="item.switch" hidden>
                                                        <span></span>
                                                        <i class="indicator"></i>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                背景颜色：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-color-picker v-model="item.backColor" show-alpha
                                                    popper-class="echarts-color-picker" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                水平位置：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-select v-model="item.level.select" placeholder="请选择">
                                                    <el-option label="靠左对齐" value="left">
                                                    </el-option>
                                                    <el-option label="居中对齐" value="center">
                                                    </el-option>
                                                    <el-option label="靠右对齐" value="right">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            <div class="charts-item-value margin-left-10">
                                                <el-input-number :disabled="item.level.select == 'center'"
                                                    v-model="item.level.value" :min="0" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                垂直位置：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-select v-model="item.vertical.select" placeholder="请选择">
                                                    <el-option label="顶部对齐" value="top">
                                                    </el-option>
                                                    <el-option label="居中对齐" value="middle">
                                                    </el-option>
                                                    <el-option label="底部对齐" value="bottom">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            <div class="charts-item-value margin-left-10">
                                                <el-input-number :disabled="item.vertical.select == 'middle'"
                                                    v-model="item.vertical.value" :min="0" :max="500" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                内边距：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-input-number v-model="item.padding[0]" :min="0" :max="500" />
                                            </div>
                                            <div class="charts-item-value margin-left-10">
                                                <el-input-number v-model="item.padding[1]" :min="0" :max="500" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="charts-second-level-cont-title red">
                                    <div></div>
                                    <div>边框配置</div>
                                </div>
                                <!-- 边框配置 -->
                                <div class="charts-second-level-content-cont red">
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                边框颜色：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-color-picker v-model="item.border.borderColor" show-alpha
                                                    popper-class="echarts-color-picker"
                                                    :disabled="item.border.borderWidth == 0" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                边框粗细：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-input-number v-model="item.border.borderWidth" :min="0" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                边框圆角：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-input-number :disabled="item.border.borderWidth == 0"
                                                    v-model="item.border.borderRadius" :min="0" />
                                            </div>
                                        </div>
                                    </div>




                                </div>
                                <!-- 主标题配置 -->
                                <div class="charts-second-level-cont-title red">
                                    <div></div>
                                    <div>主标题配置</div>
                                </div>
                                <div class="charts-second-level-content-cont red">
                                    <!-- 配置项 -->
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                主标题：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-input v-model="item.textStyle.text" placeholder="请输入" />
                                            </div>
                                            <div class="charts-item-value margin-left-10">
                                                <el-input-number v-model="item.textStyle.size" :min="0" :max="50"
                                                    :disabled="item.textStyle.text.length == 0" />
                                            </div>
                                            <div class="charts-item-value margin-left-10">
                                                <el-color-picker v-model="item.textStyle.color" show-alpha
                                                    popper-class="echarts-color-picker"
                                                    :disabled="item.textStyle.text.length == 0" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                字体风格：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-select v-model="item.textStyle.fontStyle" placeholder="请选择"    :disabled="item.textStyle.text.length == 0">
                                                    <el-option label="正常字体" value="normal">
                                                    </el-option>
                                                    <el-option label="倾斜字体" value="italic">
                                                    </el-option>
                                                    <el-option label="强制倾斜" value="oblique">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                字体粗细：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-select v-model="item.textStyle.fontWeight" placeholder="请选择"    :disabled="item.textStyle.text.length == 0">
                                                    <el-option label="正常" value="normal">
                                                    </el-option>
                                                    <el-option label="较粗" value="bold">
                                                    </el-option>
                                                    <el-option label="极粗" value="bolder">
                                                    </el-option>
                                                    <el-option label="极细" value="lighter">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                字体系列：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-select v-model="item.textStyle.fontFamily" placeholder="请选择"    :disabled="item.textStyle.text.length == 0">
                                                    <el-option label="sans-serif" value="sans-serif">
                                                    </el-option>
                                                    <el-option label="serif" value="serif">
                                                    </el-option>
                                                    <el-option label="monospace" value="monospace">
                                                    </el-option>
                                                    <el-option label="Arial" value="Arial">
                                                    </el-option>
                                                    <el-option label="Courier New" value="Courier New">
                                                    </el-option>
                                                    <el-option label="Microsoft YaHei" value="Microsoft YaHei">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 副标题配置 -->
                                <div class="charts-second-level-cont-title red">
                                    <div></div>
                                    <div>副标题配置</div>
                                </div>
                                <div class="charts-second-level-content-cont red">
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                副标题：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-input v-model="item.subtextStyle.text"
                                                    :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"
                                                    placeholder="Please input" />
                                            </div>
                                            <div class="charts-item-value margin-left-10">
                                                <el-input-number v-model="item.subtextStyle.size" :min="0" :max="50"
                                                    :disabled="item.subtextStyle.text.length == 0" />
                                            </div>
                                            <div class="charts-item-value margin-left-10">
                                                <el-color-picker v-model="item.subtextStyle.color" show-alpha
                                                    popper-class="echarts-color-picker"
                                                    :disabled="item.subtextStyle.text.length == 0" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                标题间隔：
                                            </div>
                                            <div class="charts-item-value">
                                                <el-input-number :disabled="item.subtextStyle.text.length == 0"
                                                    v-model="item.itemGap" :min="0" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- 图例配置 -->
                            <div class="charts-item-content-second-level-cont red" v-if="item.name == '图例配置'">
                                <div class="charts-second-level-cont-title red">
                                    <div></div>
                                    <div>二级容器标题</div>
                                </div>
                                <div class="charts-second-level-content-cont red">
                                    <!-- 配置项 -->
                                    <div class="charts-item">
                                        <div class="charts-item-col">
                                            <div class="charts-item-name">
                                                显示：
                                            </div>
                                            <div class="charts-item-value">
                                                <div class="charts-item-value-btn">
                                                    <label>
                                                        <!-- checked 默认选中属性 -->
                                                        <input type="checkbox" v-model="item.switch" hidden>
                                                        <span></span>
                                                        <i class="indicator"></i>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script setup>
import VanillaTilt from "vanilla-tilt";
import * as echarts from 'echarts';
import { onMounted, watch, reactive } from "vue";
const props = defineProps({
    Data: {
        type: Object,
        default: {}
    },
})
onMounted(() => {
    init()
})
// 全部数据
let dataArr = reactive({
    // 配置
    configuration: {
        // 配置数据  
        settingUp: {},
    },
    options: {},
    // dom 模板
    template: {
        // 开关
        switch: false,
        // 文字输入框
        textInput: "",
        // 数字输入框
        numberInput: 1,
        // 颜色
        color: "red"
    },
    data: {
    }
})
dataArr.configuration.settingUp = props.Data.echartsSet

watch(dataArr.configuration, (newValue, oldValue) => {
    getOptions()
    echartsInit()
}, { deep: true })
// 初始化 方法
const init = () => {
    initPage()  // 初始化页面
}
// 初始化页面 - 3D 卡片效果 - echarts 渲染
const initPage = () => {
    const targetDom = document.getElementById("echartsCont");
    VanillaTilt.init(targetDom, {
        max: 5, //最大倾斜度数
        speed: 100, //倾斜转换的速度
        glare: true, //是否开启眩光效果
        "max-glare": 0.6, //最大眩光的不透明度
    });

    getOptions()
    echartsInit()
}
const getOptions = () => {
    // dataArr.options = props.Data.getOptions()
    let oldOptions = props.Data.getOptions()
    switch (props.Data.type) {                                         		//  设定条件为i
        case "basicsPieChart":                                         //  当i为1的时候将会执行 case1 中的内容,
            const optionSetTs = props.Data.echartsSet
            // 标题配置
            if (optionSetTs.title.switch) {
                // 标题 背景颜色
                if (optionSetTs.title.backColor != null) {
                    oldOptions.title.backgroundColor = optionSetTs.title.backColor != null ? optionSetTs.title.backColor : "transparent"
                }
                // 位置配置 - 水平位置
                if (optionSetTs.title.level.select == 'center') {
                    oldOptions.title.left = 'center'
                    delete oldOptions.title.right
                } else if (optionSetTs.title.level.select == 'left') {
                    oldOptions.title.left = optionSetTs.title.level.value
                    delete oldOptions.title.right
                } else if (optionSetTs.title.level.select == 'right') {
                    oldOptions.title.right = optionSetTs.title.level.value
                    delete oldOptions.title.left
                }
                // 位置配置 - 垂直位置
                if (optionSetTs.title.vertical.select == 'middle') {
                    oldOptions.title.top = 'middle'
                    delete oldOptions.title.bottom
                } else if (optionSetTs.title.vertical.select == 'top') {
                    oldOptions.title.top = optionSetTs.title.vertical.value
                    delete oldOptions.title.bottom
                } else if (optionSetTs.title.vertical.select == 'bottom') {
                    oldOptions.title.bottom = optionSetTs.title.vertical.value
                    delete oldOptions.title.top
                }
                // 内边距配置
                oldOptions.title.padding = optionSetTs.title.padding
                // 边框配置
                if (optionSetTs.title.border.borderWidth) {
                    oldOptions.title.borderWidth = optionSetTs.title.border.borderWidth
                    oldOptions.title.borderRadius = optionSetTs.title.border.borderRadius
                    oldOptions.title.borderColor = optionSetTs.title.border.borderColor
                }
                // 主标题
                oldOptions.title.text = optionSetTs.title.textStyle.text
                if (oldOptions.title.textStyle == undefined) {
                    oldOptions.title.textStyle = {}
                }
                oldOptions.title.textStyle.color = optionSetTs.title.textStyle.color
                oldOptions.title.textStyle.fontSize = optionSetTs.title.textStyle.size
                if(optionSetTs.title.textStyle.fontStyle!=="normal"){
                    oldOptions.title.textStyle.fontStyle = optionSetTs.title.textStyle.fontStyle
                }
                if(optionSetTs.title.textStyle.fontWeight!=="normal"){
                    oldOptions.title.textStyle.fontWeight = optionSetTs.title.textStyle.fontWeight
                }
                if(optionSetTs.title.textStyle.fontFamily!=="sans-serif"){
                    oldOptions.title.textStyle.fontFamily = optionSetTs.title.textStyle.fontFamily
                }

                
                // 副标题
                oldOptions.title.itemGap = optionSetTs.title.itemGap
                oldOptions.title.subtext = optionSetTs.title.subtextStyle.text
                if (oldOptions.title.subtextStyle == undefined) {
                    oldOptions.title.subtextStyle = {}
                }
                oldOptions.title.subtextStyle.color = optionSetTs.title.subtextStyle.color
                oldOptions.title.subtextStyle.fontSize = optionSetTs.title.subtextStyle.size
            }
            break;                                  //  跳出当前   跳出循环
        case 2:                                         //  当i为2的时候将会执行case2中的内容
            break;
        default:                                       //  如果前面没有匹配的条件将会执行dafault中的内容
            console.log("没有这个图")
    }
    dataArr.options = oldOptions

}

// echarts 渲染
const echartsInit = () => {
    const targetDom = document.getElementById("echartsCont");
    const existingChartInstance = echarts.getInstanceByDom(targetDom);
    // 判断是否存在实例 若存在进行销毁
    if (existingChartInstance) {
        existingChartInstance.dispose();
    }
    const myChart = echarts.init(targetDom);
    myChart.setOption(dataArr.options);

}





</script>
<style lang="scss" scoped>
.echarts-max-u-cont {
    .red {}

    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    padding: 16px;
    display: flex;

    .charts-left {
        width: 600px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .charts-left-picture-cont {
            width: 100%;
            height: 380px;

            .charts-left-picture {
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.1);
                border-radius: 25px;
                /* 阴影 */
                box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.9);
                /* 溢出隐藏 */
                overflow: hidden;
                border-top: 1px solid rgba(255, 255, 255, 0.5);
                border-left: 1px solid rgba(255, 255, 255, 0.5);
                backdrop-filter: blur(5px);
            }
        }

        .charts-left-configuration-cont {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;

            .charts-left-configuration-item {
                margin: 5px;
                padding: 15px 20px 0px 20px;
                border-radius: 10px;
                border: 2px solid rgba(255, 255, 255, 0.4);
                border-right: 2px solid rgba(255, 255, 255, 0.2);
                border-bottom: 2px solid rgba(255, 255, 255, 0.2);
                /* 盒子阴影 */
                box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.1);
                /* 背景模糊 */
                backdrop-filter: blur(2px);
                /* 加个动画过渡，动画才不会太过生硬 */
                transition: all 0.5s;
                overflow: hidden;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 20px;
                    height: 100%;
                    background-color: #ffffff;
                    /* 元素沿X轴45横切，沿X轴右移150px */
                    transform: skewX(45deg) translateX(150px);
                    /* 动画过渡 */
                    transition: all 0.5s;

                }

                &:hover::before {
                    /* 元素沿X轴45横切，沿X轴左移150px */
                    transform: skewX(45deg) translateX(-150px);
                }

                & div:nth-child(1) {
                    text-decoration: none;
                    color: #ffffff;
                    font-size: 16px;
                    margin-bottom: 10px;
                }

                & div:nth-child(2) {
                    width: 26px;
                    margin: 0 auto;
                    height: 4px;
                    border-radius: 2px;
                    overflow: hidden;
                    background-color: #ffffff;
                    margin-bottom: 10px;
                    animation: btnBreathe 3s linear infinite;
                }

                @keyframes btnBreathe {
                    0% {
                        background-color: #ffffff;
                    }

                    10% {
                        background-color: rgb(255, 31, 31);
                    }

                    20% {
                        background-color: #ffffff;
                    }

                    30% {
                        background-color: rgb(255, 31, 31);
                    }

                    100% {
                        background-color: #fff;
                    }
                }

                .open {
                    color: #fff;
                    animation: btnOpenBreathe 3s linear infinite !important;
                }

                @keyframes btnOpenBreathe {
                    0% {
                        background-color: #9cbeff;
                    }

                    25% {
                        background-color: #1884ff;
                    }

                    50% {
                        background-color: #9cbeff;
                    }

                    75% {
                        background-color: #1884ff;
                    }

                    100% {
                        background-color: #9cbeff;

                    }
                }

            }
        }
    }

    .charts-right-cont {
        margin: 0px 20px;
        margin-right: 30px;
        flex: 1;
        overflow-y: auto;

        &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 3px rgb(22, 127, 247);
            background: rgba(162, 187, 255, 0.2);
            scrollbar-arrow-color: rgb(21, 255, 0);
        }

        &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            -webkit-box-shadow: inset 0 0 3px rgba(156, 219, 255, 0.521);
            border-radius: 0;
            background: rgba(144, 185, 238, 0.1);
        }

        .charts-right {
            display: flex;
            flex-wrap: wrap;

            .charts-right-item-cont {
                flex-shrink: 0;
                margin: 10px 10px;
                border-radius: 10px;
                border: 2px solid rgba(255, 255, 255, 0.4);
                border-right: 2px solid rgba(255, 255, 255, 0.2);
                border-bottom: 2px solid rgba(255, 255, 255, 0.2);
                /* 盒子阴影 */
                box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.1);
                /* 背景模糊 */
                backdrop-filter: blur(10px);
                overflow: hidden;
                align-self: flex-start;

                .charts-right-item-title-cont {
                    padding-top: 10px;

                    & div:nth-child(1) {
                        letter-spacing: 1px;
                        font-size: 18px;
                        font-weight: bold;
                        margin: 0px 10px;
                        background: linear-gradient(to bottom, rgb(83, 163, 255), rgb(231, 242, 255), rgb(96, 170, 255));
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    & div:nth-child(2) {
                        margin-top: 7px;
                        height: 1px;
                        background-image: linear-gradient(45deg, rgba(38, 140, 255, 0.83), rgba(0, 0, 0, 0));
                        ;
                    }
                }

                .charts-right-item-content-cont {
                    margin-top: 10px;

                    .charts-item-content-second-level-cont {
                        margin: 0px 10px;

                        .charts-second-level-cont-title {
                            display: flex;
                            align-items: center;
                            padding-bottom: 5px;
                            border-bottom: 1px solid rgba(255, 137, 27, 0.438);

                            & div:nth-child(1) {
                                width: 4px;
                                border-radius: 2px;
                                height: 20px;
                                background-color: #ffffff;
                                margin-right: 10px;
                            }

                            & div:nth-child(2) {
                                font-size: 16px;
                                color: #abd5ff;
                            }
                        }

                        .charts-second-level-content-cont {
                            margin: 10px 0px;
                            background-color: #38383879;
                            border-radius: 5px;
                            padding: 10px 0px;
                            padding-right: 10px;
                            // border-bottom: 1px solid rgba(174, 0, 255, 0.671);


                            .charts-item {
                                margin: 8px 10px;
                                display: flex;

                                .charts-item-col {
                                    flex: 1;
                                    display: flex;

                                    .charts-item-name {
                                        padding-top: 5px;
                                        min-width: 70px;
                                        text-align: right;
                                        font-size: 14px;
                                        color: #fff;
                                        margin-right: 5px;
                                        /* 文字不换行 */
                                        white-space: nowrap;
                                    }

                                    .charts-item-value {
                                        .el-textarea {
                                            width: 150px;

                                            ::v-deep .el-textarea__inner {
                                                background-color: transparent;
                                                color: rgba(74, 159, 255, 0.8);
                                                box-shadow: inset 0px 0px 0px 1px rgba(74, 159, 255, 0.6);

                                                &::-webkit-scrollbar {
                                                    /*滚动条整体样式*/
                                                    width: 2px;
                                                    /*高宽分别对应横竖滚动条的尺寸*/
                                                    scrollbar-arrow-color: rgb(7, 49, 235);
                                                }

                                                &::-webkit-scrollbar-thumb {
                                                    /*滚动条里面小方块*/
                                                    border-radius: 5px;
                                                    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
                                                    background: rgb(0, 102, 255);
                                                    scrollbar-arrow-color: rgba(0, 0, 0, 0.2);
                                                }

                                                &::-webkit-scrollbar-track {
                                                    /*滚动条里面轨道*/
                                                    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
                                                    border-radius: 0;
                                                    background: rgba(9, 255, 0, 0.1);
                                                }
                                            }
                                        }

                                        .el-input,
                                        .el-select {
                                            width: 150px;

                                            ::v-deep .el-input__wrapper {
                                                border-top: 0px;
                                                background-color: transparent;
                                                box-shadow: inset 0px 0px 0px 1px rgba(74, 159, 255, 0.6);

                                                .el-input__inner {
                                                    color: rgba(74, 159, 255, 0.8);
                                                }
                                            }

                                            ::v-deep .el-input__wrapper.is-focus {
                                                box-shadow: inset 0px 0px 0px 1px rgba(74, 159, 255, 1);

                                                .el-input__inner {
                                                    color: rgba(74, 159, 255, 1);
                                                }
                                            }
                                        }
                                        ::v-deep .el-input-number.is-disabled{ 
                                           .el-input__wrapper {
                                               box-shadow: inset 0px 0px 0px 1px rgba(80, 80, 80, 0.726);
                                                background-color: #5f5f5f70;
                                           }
                                           .el-input-number__increase{
                                            background-color: rgba(193,193,193, 0.36);
                                            
                                           }
                                           .el-input-number__decrease{
                                            background-color: rgba(193,193,193, 0.36);
                                            
                                           }
                                       }
                                        .el-input-number {
                                            ::v-deep .el-input__wrapper.is-focus {
                                                box-shadow: inset 0px 0px 0px 1px rgb(170, 210, 255);
                                            }

                                            ::v-deep .el-input__wrapper {
                                                box-shadow: inset 0px 0px 0px 1px rgba(64,64 , 64, 0.726); 
                                                background-color: transparent;
                                            }
                                         
                                           
                                            ::v-deep .el-input-number__decrease {
                                                background-color: rgba(255,255,255, 0.6);
                                                border-right-color: rgba(96,96, 96, 1);
                                                color: rgb(255, 255, 255);
                                            }

                                            ::v-deep .el-input-number__increase {
                                                background-color: rgba(255,255,255, 0.6);
                                                border-left-color: rgba(96,96, 96, 1);
                                                color: rgb(255, 255, 255);
                                            }

                                            ::v-deep .el-input__inner {
                                                color: rgba(74, 159, 255, 0.8);
                                            }



                                        }

                                        .charts-item-value-btn {
                                            display: flex;
                                            flex-direction: column;

                                            label {
                                                width: 40px;
                                                /* 相对定位 */
                                                position: relative;
                                                margin: 5px 0;
                                                cursor: pointer;

                                                span {
                                                    position: relative;
                                                    display: block;
                                                    width: 40px;
                                                    height: 20px;
                                                    background-color: #222;
                                                    border-radius: 20px;
                                                    /* 内阴影 */
                                                    box-shadow: inset 0 2px 15px rgba(0, 0, 0, 0.2),
                                                        inset 0 2px 2px rgba(0, 0, 0, 0.2),
                                                        inset 0 -1px 1px rgba(0, 0, 0, 0.2);
                                                }

                                                .indicator {
                                                    /* 绝对定位 */
                                                    position: absolute;
                                                    left: 0;
                                                    top: 0;
                                                    width: 20px;
                                                    height: 20px;
                                                    /* 渐变背景 */
                                                    background: linear-gradient(to bottom, #444, #222);
                                                    border-radius: 50%;
                                                    /* 阴影 */
                                                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5),
                                                        inset 0 1px 1px rgba(255, 255, 255, 0.1);
                                                    /* 缩小 */
                                                    transform: scale(0.9);
                                                    /* 动画过渡 */
                                                    transition: 0.5s;
                                                }

                                                .indicator::before {
                                                    content: "";
                                                    width: 5px;
                                                    height: 5px;
                                                    /* 绝对定位 居中 */
                                                    position: absolute;
                                                    left: 50%;
                                                    top: 50%;
                                                    transform: translate(-50%, -50%);
                                                    background-color: #f00;
                                                    border-radius: 50%;
                                                    /* 阴影制造发光效果 */
                                                    box-shadow: 0 0 2px #f00,
                                                        0 0 5px #f00,
                                                        0 0 10px #f00,
                                                        0 0 15px #f00,
                                                        0 0 20px #f00,
                                                        0 0 25px #f00,
                                                        0 0 30px #f00,
                                                        0 0 35px #f00;
                                                    transition: 0.5s;
                                                }

                                                input:checked~.indicator {
                                                    left: 20px;
                                                }

                                                input:checked~.indicator::before {
                                                    background-color: #0f0;
                                                    box-shadow: 0 0 2px #0f0,
                                                        0 0 5px #0f0,
                                                        0 0 10px #0f0,
                                                        0 0 15px #0f0,
                                                        0 0 20px #0f0,
                                                        0 0 25px #0f0,
                                                        0 0 30px #0f0,
                                                        0 0 35px #0f0;
                                                }
                                            }
                                        }




                                    }

                                    .margin-left-10 {
                                        margin-left: 10px;
                                    }

                                }



                            }

                        }
                    }

                }

            }
        }

        .red {}


    }

}
</style>